<template>
  <div>
    <Loading v-if="isLoading"></Loading>
    <div class="container p-4">
      <h1 class="p-4">{{ page.title }}</h1>
      <div class="p-4" v-html="page.content"></div>
    </div>

    <!-- Footer -->
    <Footer> </Footer>
    <!-- Footer -->
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../components/Loading.vue";
import Footer from "../components/FooterPage.vue";
import { url, key } from "../config/main";
export default {
  components: {
    Footer,
    Loading,
  },
  data() {
    return {
      page: [],
      isLoading: true,
    };
  },
  created() {
    this.getPage();
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  },
  methods: {
    getPage() {
      let config = {
        method: "get",
        url: `${url}/api/v1/pages/1`,
        headers: {
          Authorization: key,
        },
        timeout: 10000,
      };

      axios(config)
        .then((response) => {
          this.page = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
