<template>
<div>
    <Loading v-if="isLoading"></Loading>
    <div class="container p-4">
        <div class="row">
            <!-- MENU -->
            <div class="col-lg-3" style="border: solid 1px #dae2e6">
                <h3 class="pt-2">Categorias</h3>

                <div class="accordion" id="accordionExample">
                    <div class="menu-titular" v-for="category in catreverse" :key="category.id">
                        <div :id="'head' + category.id" style="border-bottom: 1px solid #dae2e6">
                            <h2 class="mb-0">
                                <a class="menu-font-titular btn-categorias" type="button" data-toggle="collapse" :data-target="'#collapse' + category.id" aria-expanded="true" :aria-controls="'collapse' + category.id">
                                    {{ category.name }}
                                    <i class="pull-right bi bi-chevron-down mt-1"></i>
                                </a>
                            </h2>
                        </div>

                        <div :id="'collapse' + category.id" class="collapse" :aria-labelledby="'head' + category.id" data-parent="#accordionExample">
                            <div>
                                <ul>
                                    <li v-for="item in category.subcategories" :key="item.id">
                                        <router-link :to="'/productos/' + item.id" class="text-decoration-none menu-font-titular btn-categorias">{{ item.name }}</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- CATALOGO -->
            <div class="col-lg-9">
                <div class="row p-4" style="margin-bottom: 25px">
                    <div class="col-lg-6"><span>Todos los Productos</span></div>
                    <div class="col-lg-2"></div>
                    <div class="col-lg-4 text-right">
                        <input type="text" v-model="search" class="form-control" placeholder="Buscar Producto.." />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4" v-for="product in pageOfItems.slice(0, 9)" :key="product.id">
                        <div class="card mb-4 product-wap rounded-0">
                            <div class="card rounded-0">
                                <img class="card-img rounded-0 img-fluid" :src="
                      'https://backs.targetmarket.cl/public/img/producto/' +
                      product.imagen
                    " />
                                <div class="card-img-overlay rounded-0 product-overlay d-flex align-items-center justify-content-center">
                                    <ul class="list-unstyled">
                                        <li>
                                            <a class="btn btn-success text-white mt-2" data-toggle="modal" :data-target="'#product' + product.id"><i class="far fa-eye"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="card-body text-center">
                                <a href="" class="h3 text-decoration-none">{{
                    product.title
                  }}</a>
                                <hr />

                                <button type="button" class="btn btn-danger text-white" data-toggle="modal" :data-target="'#product' + product.id">
                                    <i class="bi bi-bag-fill"></i> Cotizar
                                </button>
                            </div>
                        </div>

                        <!-- Modal -->
                        <div class="modal fade" :id="'product' + product.id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-xl" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">
                                            {{ product.title }}
                                        </h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <img class="card-img rounded-0 img-fluid" :src="
                              'https://backs.targetmarket.cl/public/img/producto/' +
                              product.imagen
                            " />
                                                <p class="card p-1 mt-2" v-html="product.description"></p>
                                                <strong>SKU:</strong> {{ product.sku }}<br />
                                                <strong>TAMAÑOS:</strong>
                                                <p v-html="product.size"></p>
                                                <strong>COLOR:</strong>
                                                <p v-html="product.color"></p>
                                            </div>
                                            <div class="col-lg-8">
                                                <ul class="nav nav-tabs">
                                                    <li class="active"><a data-toggle="tab" href="#home">Home</a></li>
                                                    <li><a data-toggle="tab" href="#menu1">Menu 1</a></li>
                                                    <li><a data-toggle="tab" href="#menu2">Menu 2</a></li>
                                                    <li><a data-toggle="tab" href="#menu3">Menu 3</a></li>
                                                </ul>
                                                <div class="tab-content">
                                                    <div id="home" class="tab-pane fade in active">
                                                        <h3>HOME</h3>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                    </div>
                                                    <div id="menu1" class="tab-pane fade">
                                                        <h3>Menu 1</h3>
                                                        <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                    </div>
                                                    <div id="menu2" class="tab-pane fade">
                                                        <h3>Menu 2</h3>
                                                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                                                    </div>
                                                    <div id="menu3" class="tab-pane fade">
                                                        <h3>Menu 3</h3>
                                                        <p>Eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                                                    </div>
                                                </div>

                                                <Form v-bind:producto="product.id" v-bind:names="product.title"></Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Modal -->
                    </div>
                </div>
                <div class="row justify-content-center">
                    <jw-pagination class="col-lg-8" :items="filteredList" @changePage="onChangePage" :labels="customLabels" :disableDefaultStyles="true"></jw-pagination>
                </div>
            </div>
        </div>
    </div>

    <Footer></Footer>
</div>
</template>

<script>
import axios from "axios";
import Loading from "../components/Loading.vue";
import {
    url,
    key
} from "../config/main";
import Footer from "../components/FooterPage.vue";
import Form from "../components/Quatation.vue";

const customLabels = {
    first: "Primero",
    last: "Ultimo",
    previous: "Anterior",
    next: "Siguiente",
};

export default {
    data() {
        return {
            categories: [],
            category: [],
            productos: [],
            product: [],
            search: "",
            pageOfItems: [],
            customLabels,
            isLoading: true,
            catreverse: [],
        };
    },
    components: {
        Footer,
        Form,
        Loading,
    },

    created() {
        this.getCategory();
        this.getProducts();
    },
    methods: {
        getProducts() {
            const data = {
                method: "get",
                url: `${url}/api/v1/products`,
                headers: {
                    Accept: "application/json",
                    Authorization: key,
                },
                timeout: 10000,
            };
            axios(data)
                .then((responses) => {
                    this.productos = responses.data.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        onChangePage(pageOfItems) {
            // update page of items
            this.pageOfItems = pageOfItems;
        },

        getCategory() {
            let config = {
                method: "get",
                url: `${url}/api/v1/categories`,
                headers: {
                    Accept: "application/json",
                    Authorization: key,
                },
                timeout: 10000,
            };

            axios(config)
                .then((response) => {
                    this.categories = response.data.data;
                    this.catreverse = this.categories.reverse();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    computed: {
        filteredList: function () {
            return this.productos.filter((product) => {
                return product.title.toLowerCase().includes(this.search.toLowerCase());
            });
        },
    },
    mounted() {
        setTimeout(() => {
            this.isLoading = false;
        }, 1000);
    },
};
</script>

<style>
.menu-font-titular {
    color: #000;
    text-decoration: none;
    font-weight: 900;
    font-size: 13px !important;
    margin-bottom: 5px;
}

.menu-font-titular:hover {
    color: #000;
    text-decoration: none;
}

.btn-categorias {
    padding: 5px;
    color: #000;
    border-radius: 5px;
}

.btn-categorias:hover {
    background-color: #b0161e;
    padding: 5px;
    color: #fff;
    border-radius: 5px;
}

.btn-pagination {
    background-color: #b0161e;
    color: #fff;
    margin: 1px;
}

.btn-pagination:hover {
    background-color: #b0161e;
}

.btn-pagination:active {
    background-color: #b0161e;
}

.btn-pagination::selection {
    background-color: #b0161e;
}

.pagination ul {
    width: 100% !important;
    text-align: center;
    padding: 10px;
}

/* - Pagination component container (ul element) */
.pagination li {
    text-align: center;
    background-color: #b0161e;
}

/* All list items in the pagination component */
.pagination li a {
    text-align: center;
    background-color: #b0161e;
    color: #fff;
}

/* All pagination links including first, last, previous and next */
.pagination li.page-number {
    text-align: center;
    background-color: #b0161e;
}

/* All page numbers (1, 2, 3 etc) pagination elements  */

.pagination li.page-number .active {
    text-align: center;
    background-color: #b0161e;
}

/* All page numbers (1, 2, 3 etc) pagination elements  */
.pagination li.first {
    display: none;
}

/* The 'First' pagination element */

.pagination li.last {
    display: none;
}

/* The 'Last' pagination element */
.pagination li.previous {
    text-align: center;
    background-color: #b0161e;
}

/* The 'Previous' pagination element */

.pagination li.next {
    text-align: center;
    background-color: #b0161e;
}

/* The 'Next' pagination element */
.page-item.active .page-link {
    background-color: #212934;
    border: none !important;
    margin: 1px;
}
</style>
