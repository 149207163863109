<template>
  <div>
    <Loading v-if="isLoading"></Loading>
    <div class="container p-4">
      <div class="row">
        <div class="col-lg-4 p-4">
          <div v-html="page.content"></div>
        </div>

        <div class="col-lg-8">
          <form class="card p-4 text-dark" v-on:submit.prevent="createContact">
            <h5>Formulario de Contacto</h5>
            <hr />
            <div class="row mb-2">
              <div class="col-lg-3">Tu Nombre</div>
              <div class="col-lg-9">
                <input
                  ref="form"
                  v-model="form.name"
                  class="form-control"
                  type="text"
                  placeholder=""
                  required
                />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-lg-3">Tu Email</div>
              <div class="col-lg-9">
                <input
                  ref="form"
                  v-model="form.email"
                  class="form-control"
                  type="email"
                  placeholder=""
                  required
                />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-lg-3">Asunto</div>
              <div class="col-lg-9">
                <input
                  ref="form"
                  v-model="form.case"
                  class="form-control"
                  type="text"
                  placeholder=""
                  required
                />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-lg-3">Celular</div>
              <div class="col-lg-9">
                <input
                  ref="form"
                  v-model="form.phone"
                  class="form-control"
                  type="text"
                  placeholder=""
                  required
                />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-lg-3">Mensaje</div>
              <div class="col-lg-9">
                <textarea
                  name=""
                  ref="form"
                  v-model="form.commet"
                  class="form-control"
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-lg-3"></div>
              <div class="col-lg-9 text-right">
                <button class="btn btn-danger btn-lg">Enviar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <Footer> </Footer>
    <!-- Footer -->
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../components/Loading.vue";
import Footer from "../components/FooterPage.vue";
import { url, key } from "../config/main";

export default {
  components: {
    Loading,
    Footer,
  },
  data() {
    return {
      form: {
        name: "",
        phone: "",
        case: "",
        email: "",
        comment: "",
      },
      isLoading: true,
      page: [],
    };
  },

  created() {
    this.getPage();
  },

  methods: {
    showAlert() {
      // Use sweetalert2
      this.$swal({
        title: "Formulario Enviado",
        text: "Gracias por completar el formulario, a la brevedad una de nuestras ejecutivas te contactara",
        icon: "success",
        confirmButtonText: "Cerrar",
      });
    },
    createContact() {
      let data = JSON.stringify({
        name: this.form.name,
        email: this.form.email,
        phone: this.form.phone,
        case: this.form.case,
        comment: this.form.comment,
      });

      let config = {
        method: "post",
        url: `${url}/api/v1/contacts`,
        headers: {
          Authorization: key,
          "Content-Type": "application/json",
        },
        timeout: 10000,
        data: data,
      };

      axios(config)
        .then(() => {
          this.showAlert();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getPage() {
      let config = {
        method: "get",
        url: `${url}/api/v1/pages/2`,
        headers: {
          Authorization: key,
        },
        timeout: 10000,
      };

      axios(config)
        .then((response) => {
          this.page = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1500);
  },
};
</script>

<style></style>
