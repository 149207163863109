<template>
  <div>
    <Loading v-if="isLoading"></Loading>
    <div class="container p-4">
      <div class="row p-4">
        <div class="col-lg-6" v-for="item in catalogue" :key="item.id">
          <div class="card m-1 p-2" style="min-height: 270px">
            <div class="row">
              <div class="col-lg-5 text-center">
                <img :src="item.imagen.url" class="w-100 h-100" alt="" />
              </div>
              <div class="col-lg-7">
                <h5>{{ item.name }}</h5>
                <div class="text-sm" v-html="item.description"></div>
                <hr />

                <a :href="item.url" target="_black" class="btn btn-danger"
                  >Ver Catalogo</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import axios from "axios";
import Footer from "../components/FooterPage.vue";
import Loading from "../components/Loading.vue";
import { key, url } from "../config/main";
export default {
  components: {
    Footer,
    Loading,
  },
  data() {
    return {
      catalogue: [],
      isLoading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1500);
  },
  methods: {
    getCatalogues() {
      let config = {
        method: "get",
        url: `${url}/api/v1/catalogues`,
        headers: {
          Authorization: key,
        },
        timeout: 10000,
      };

      axios(config)
        .then((response) => {
          this.catalogue = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getCatalogues();
  },
};
</script>

<style scoped>
.text-sm {
  font-size: 10px !important;
}

.items {
  min-height: 400px !important;
}

.card-body {
  height: 200px;
}
</style>
